<template>
  <div id="app">
    <div id="maps">
      <reportMap></reportMap>
    </div>
    <div id="container">
      <div id="permanent-navbar" class="mt-4 mb-4">
         <b-button class="btn-light" variant="light" :class="positiveClass()" v-on:click="positiveButton">Positive</b-button>
         <b-button class="btn-light" variant="light" :class="allClass()" v-on:click="allButton" v-show="this.$store.state.states.global.spotButtonState">All</b-button>
         <b-button id="addSpotButton" v-on:click="addSpot" v-show="!this.$store.state.states.global.spotButtonState" :style="{ 'background-color': this.$store.state.styling.spotButtonColor }">Add Spot</b-button>
         <b-button class="btn-light" variant="light" :class="negativeClass()" v-on:click="negativeButton">Negative</b-button>
      </div>
      <router-view></router-view>
    </div>
    <div id="nav-outer">
      <nav>
        <router-link class="nav-item" to = "/"><i class="material-icons">add</i></router-link>
        <router-link class="nav-item" to = "/location"><i class="material-icons">dehaze</i></router-link>
        <router-link class="nav-item" to = "/search"><i class="material-icons">search</i></router-link>
        <router-link class="nav-item" to = "/favorites"><i class="material-icons">star_outline</i></router-link>
      </nav>
    </div>
  </div>
</template>

<script>

import ReportMap from '@/components/global/map.vue';

export default {
  components: {
      ReportMap,
  },
  methods: {
    positiveClass: function () {

      if(this.$route.name == "home") {
        return this.$store.state.states.addPage.positiveStateButton?'btn-active':'';
      }

      if(this.$route.name == "userLocation") {
        return this.$store.state.states.userLocation.positiveStateButton?'btn-active':'';
      }

      if(this.$route.name == "search") {
        return this.$store.state.states.search.positiveStateButton?'btn-active':'';
      }

      if(this.$route.name == "favorites") {
        return this.$store.state.states.favorites.positiveStateButton?'btn-active':'';
      }

    },
    allClass: function() {

      if(this.$route.name == "userLocation") {
        return this.$store.state.states.userLocation.allStateButton?'btn-active':'';
      }

      if(this.$route.name == "search") {
        return this.$store.state.states.search.allStateButton?'btn-active':'';
      }

      if(this.$route.name == "favorites") {
        return this.$store.state.states.favorites.allStateButton?'btn-active':'';
      }

    },
    negativeClass: function() {

      if(this.$route.name == "home") {
        return this.$store.state.states.addPage.negativeStateButton?'btn-active':'';
      }

      if(this.$route.name == "userLocation") {
        return this.$store.state.states.userLocation.negativeStateButton?'btn-active':'';
      }

      if(this.$route.name == "search") {
        return this.$store.state.states.search.negativeStateButton?'btn-active':'';
      }

      if(this.$route.name == "favorites") {
        return this.$store.state.states.favorites.negativeStateButton?'btn-active':'';
      }
  
    },
    positiveButton: function () {

      if(this.$route.name == "home") {

        this.$store.commit('changeHomeCondition', 'positive');
        this.$store.commit('changeSpotButtonColor', '#32d432');
        
        this.$store.state.states.addPage.positiveStateButton = true;
        this.$store.state.states.addPage.negativeStateButton = false;

      }

      if(this.$route.name == "userLocation") {

        this.$store.commit('changeUserLocationCondition', 'positive');

        this.$store.state.states.userLocation.positiveStateButton = true;
        this.$store.state.states.userLocation.allStateButton = false;
        this.$store.state.states.userLocation.negativeStateButton = false;

      }

      if(this.$route.name == "search") {

        this.$store.commit('changeSearchCondition', 'positive');

        this.$store.state.states.search.positiveStateButton = true;
        this.$store.state.states.search.allStateButton = false;
        this.$store.state.states.search.negativeStateButton = false;

      }

      if(this.$route.name == "favorites") {

        this.$store.commit('changeFavoriteCondition', 'positive');

        this.$store.state.states.favorites.positiveStateButton = true;
        this.$store.state.states.userLocation.allStateButton = false;
        this.$store.state.states.favorites.negativeStateButton = false;

      }

    },
    allButton: function () {

      if(this.$route.name == "userLocation") {

        this.$store.commit('changeUserLocationCondition', 'all');
        
        this.$store.state.states.userLocation.allStateButton = true;
        this.$store.state.states.userLocation.positiveStateButton = false;
        this.$store.state.states.userLocation.negativeStateButton = false;

      }

      if(this.$route.name == "search") {
        
        this.$store.commit('changeSearchCondition', 'all');

        this.$store.state.states.search.allStateButton = true;
        this.$store.state.states.search.positiveStateButton = false;
        this.$store.state.states.search.negativeStateButton = false;

      }

      if(this.$route.name == "favorites") {

        this.$store.commit('changeFavoriteCondition', 'all');

        this.$store.state.states.favorites.allStateButton = true;
        this.$store.state.states.favorites.positiveStateButton = false;
        this.$store.state.states.favorites.negativeStateButton = false;

      }

    },
    addSpot: function () {
    
    },
    negativeButton: function () {

      if(this.$route.name == "home") {
        this.$store.commit('changeHomeCondition', 'negative');
        this.$store.commit('changeSpotButtonColor', 'red');
        
        this.$store.state.states.addPage.positiveStateButton = false;
        this.$store.state.states.addPage.negativeStateButton = true;

      }

      if(this.$route.name == "userLocation") {

        this.$store.commit('changeUserLocationCondition', 'negative');

        this.$store.state.states.userLocation.negativeStateButton = true;
        this.$store.state.states.userLocation.allStateButton = false;
        this.$store.state.states.userLocation.positiveStateButton = false;

      }

      if(this.$route.name == "search") {

        this.$store.commit('changeSearchCondition', 'negative');

        this.$store.state.states.search.negativeStateButton = true;
        this.$store.state.states.search.allStateButton = false;
        this.$store.state.states.search.positiveStateButton = false;

      }

      if(this.$route.name == "favorites") {

        this.$store.commit('changeFavoriteCondition', 'negative');

        this.$store.state.states.favorites.negativeStateButton = true;
        this.$store.state.states.favorites.allStateButton = false;
        this.$store.state.states.favorites.positiveStateButton = false;

      }

    }
  },
  beforeCreate:function() {
    
    // Get User Location
    this.$store.commit('requestUserLocation');

    // Load tags
    this.$store.commit('getTagsFromDB');

    // Load spots
    this.$store.commit('getSpotsFromDB');

  },
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html, body {
  margin:0;
  padding:0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12pt;
}

.btn {
  background-color: white;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
  box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
  border-radius: .5rem !important;
  border: none;
  padding: .5rem;
  margin: 0 .25rem 0 .25rem;
}

.btn-active {
  background-color: lightgrey !important;
}

nav {
  display: flex;
  background-color: white;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
  box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
  border-radius: .5rem;
  position: fixed;
  bottom: 0;
  padding: 1rem;
  margin-bottom: 1rem;
}

nav .nav-item {
  margin: 1rem;
  padding: .5rem;
  color: black;
  text-decoration: none;
  font-size: 12pt;
}

.material-icons {
    vertical-align: text-bottom;
    margin-bottom: -2px;
}

#nav-outer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.router-link-exact-active {
  border-radius: .5rem !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
  box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
}

.popover-body {
  border: 0 !important;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(184,178,184,1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(184,178,184,1);
  box-shadow: 0px 0px 15px 0px rgba(184,178,184,1);
}

#maps {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height:100vh !important;
}

#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#container {
  position: fixed;
  bottom: 0px;
  padding-bottom: 100px !important;
  height: 33.3%;
  min-height: 350px;
  width: 100%;
  background: rgba(255,255,255,1);
  padding-bottom: 45px;
  overflow: auto;
}

#addSpotButton {
  background-color: #32d432;
  color: white;
  font-size: 12pt;
  border: 0px;
  border-radius: 360px !important;
  width: 150px;
  height: 150px;
  margin: .75rem;
}

</style>

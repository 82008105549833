// Import dependencies
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

// Import custom Global Storage
import { store } from './store.js'

// Set-Up config settings
Vue.config.productionTip = false

// Create Vue
new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
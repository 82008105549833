// Import dependencies
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

// Use dependencies
Vue.use(Vuex)

// Create store-container
export const store = new Vuex.Store({
  state: {
      api: {
        apiDomain: 'https://vue.fabiangerth.com/',
        apiGetPath: 'scripts/actions/get/',
        apiSetPath: 'scripts/actions/set/',
      },
      user: {
        curUserLatitude: 0,
        curUserLongitude: 0,
        curCenterLatitude: 0,
        curCenterLongitude: 0,
        curMapZoom: 0,
        curUserID: 1,
      },
      arrays: {
        allTags: [],
        allSpots: [],
        mySpots: [],
        mapMarker: [],
      },
      styling: {
        spotButtonColor: '#32d432',
      },
      conditions: {
        homeCondition: 'positive',
        userLocationCondition: 'positive',
        favoriteCondition: 'positive',
        userLocationSort: null,
      },
      textStrings: {
        
      },
      states: {
        global: {
          spotButtonState: false,
        },
        addPage: {
          positiveStateButton: true,
          negativeStateButton: false,
        },
        userLocation: {
          positiveStateButton: true,
          allStateButton: false,
          negativeStateButton: false,
        },
        search: {
          positiveStateButton: true,
          allStateButton: false,
          negativeStateButton: false,
        },
        favorites: {
          positiveStateButton: true,
          allStateButton: false,
          negativeStateButton: false,
        }
      }
  },
  mutations: {
    changeHomeCondition(state, givenValue) {
      state.conditions.homeCondition = givenValue;
    },
    changeUserLocationCondition(state, givenValue) {
      state.conditions.userLocationCondition = givenValue;
    },
    changeSearchCondition(state, givenValue) {
      state.conditions.userLocationCondition = givenValue;
    },
    changeFavoriteCondition(state, givenValue) {
      state.conditions.userLocationCondition = givenValue;
    },
    changeSpotButtonState(state, givenValue) {
      state.states.global.spotButtonState = givenValue;
    },
    changeSpotButtonColor(state, givenValue) {
      state.styling.spotButtonColor = givenValue;
    },
    changeUserLocationSort(state, givenValue) {
      state.conditions.userLocationSort = givenValue;
    },
    getTagsFromDB(state) {
      axios.get(state.api.apiDomain + state.api.apiGetPath + 'getTags.php')
      .then((response) => {
          state.arrays.allTags = response.data.tags;
      })
      .catch(error => console.log(error))
    },
    getSpotsFromDB(state) {
      axios.get(state.api.apiDomain + state.api.apiGetPath + 'getSpots.php')
      .then((response) => {
          state.arrays.allSpots = response.data.spots;
      })
      .catch(error => console.log(error))
    },
    getMySpotsFromDB(state) {
      axios.get(state.api.apiDomain + state.api.apiGetPath + 'login/getMySpots.php')
      .then((response) => {
          state.arrays.mySpots = response.data.mySpots;
      })
      .catch(error => console.log(error))
    },
    requestUserLocation(state) {
      
      navigator.geolocation.getCurrentPosition(
         position => {
            state.user.curUserLatitude = position.coords.latitude;
            state.user.curUserLongitude = position.coords.longitude;
         },
         error => {
           console.log(error.message);
         },
         {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0,
         }
         
      ) 
    },
    clearMapMarker(state) {
      state.arrays.mapMarker = [];
    },
    setMapMarker(state, data) {
      state.arrays.mapMarker.push([data['id'],data['lat'],data['lng']]);
    },
    setMapCenter(state, data) {
      state.user.curCenterLatitude = data['lat'];
      state.user.curCenterLongitude = data['lng'];
    },
    setMapZoom(state, data) {
      state.user.curMapZoom = data;
    }
  },
});
import Vue from 'vue'
import Router from 'vue-router'

// Import pages
import AddSpots from '@/components/activities/add'
import SearchSpots from '@/components/activities/search'
import UserLocation from '@/components/activities/userLocation'
import Favorites from '@/components/activities/favorites'

// Use Router
Vue.use(Router);

// Set routes
const router = new Router({
    routes: [
        {
            name: 'home',
            path: '/',
            component: AddSpots,
            meta: {
                title: "Sportreport: Home",
            },
        },
        {
            name: 'userLocation',
            path: '/location',
            component: UserLocation,
            meta: {
                title: "Sportreport: Location",
            },
        },
        {
            name: 'search',
            path: '/search',
            component: SearchSpots,
            meta: {
                title: "Sportreport: Search",
            },
        },
        {
            name: 'favorites',
            path: '/favorites',
            component: Favorites,
            meta: {
                title: "Sportreport: Favorites",
            },
        }
    ]
})

// Set defaultURL
var defaultURL = "Spotreport";

// Write Title-Tag
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || defaultURL
    next()
})

// Export constant
export default router
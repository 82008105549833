<template>
    <div>
        <b-container class="mt-4 mb-4">
            <b-row>
                <b-col><b-form-input v-model="textFilter" placeholder="search: min 3 letters"></b-form-input></b-col>
                <b-col>
                    <b-form-select v-model="selectedFilter">
                        <b-form-select-option :value="null" disabled>Sort by ...</b-form-select-option>
                        <b-form-select-option value="sortByTag">Sort by Tag</b-form-select-option>
                        <b-form-select-option value="sortByTitle">Sort by Title</b-form-select-option>
                        <b-form-select-option value="sortByLocation">Sort by Location</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row> 
        </b-container>
        <div v-show="!contentLoaded">
            <h3>Search results</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchContainer",
    data() {
        return {
            contentLoaded: Boolean,
            selectedFilter: null,
            textFilter: null,
        }
    },
    mounted() {

        // Disable the "Add Spot" Button
        this.$store.commit('changeSpotButtonState', true);

        // Set Content to loaded
        this.contentLoaded = false; 
    }
}
</script>

<style scoped>
.searchbar {
    margin: 1.5rem 0 1.5rem 0;
    max-width: 1000px;
    width: 100%;
}
</style>
<template>
    <div>
        <b-container class="mt-4 mb-4">
        <b-row>
            <b-col></b-col>
            <b-col></b-col>
            <b-col>
                <b-form-select v-model="selectedFilter">
                    <b-form-select-option :value="null" disabled>Sort by ...</b-form-select-option>
                    <b-form-select-option value="sortByDistance">Sort by Distance</b-form-select-option>
                    <b-form-select-option value="sortByTag">Sort by Tag</b-form-select-option>
                    <b-form-select-option value="sortByTitle">Sort by Title</b-form-select-option>
                    <b-form-select-option value="sortByDate">Sort by Date</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        </b-container>
        <div v-show="!contentLoaded">
            <h3>Select a spot</h3>
        </div>
        <div>
            <ul class="center-align">
                    <li class="tag" :id="'tag-information-' + spots.id" v-for="spots in filteredTags" :key="spots.id">
                        <h3>0 km</h3>
                        {{ spots.title }}
                        <b-popover :target="'tag-information-' + spots.id" triggers="hover" placement="top">
                            <template v-slot:title>{{ spots.name }}</template>
                            {{ spots.information }}
                        </b-popover>
                    </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "DistancesContainer",
    data() {
        return {
            contentLoaded: Boolean,
            selectedFilter: null,
        }
    },
    computed: {
        filteredTags() {

            // Handle unset 
            if (!this.$store.state.arrays.allSpots) return [];

            // Clear all Markers from Map
            this.$store.commit("clearMapMarker");

            // Filter Array
            return this.$store.state.arrays.allSpots.filter(spots =>  {

                if(this.$store.state.conditions.userLocationCondition == "all") {

                    // Set Content to loaded
                    this.contentLoaded = false;

                    // Show Markers on Map
                    this.$store.commit("setMapZoom", 7);
                    this.$store.commit("setMapMarker", { 'id': spots.id, 'lat': spots.latitudeMaster, 'lng': spots.longitudeMaster });

                    // Return Array-Value
                    return true;

                }

                // Use userLocationCondition
                if(spots.rank.includes(this.$store.state.conditions.userLocationCondition)) {

                    // Set Content to loaded
                    this.contentLoaded = false;

                    // Show Markers on Map
                    this.$store.commit("setMapZoom", 7);
                    this.$store.commit("setMapMarker", { 'id': spots.id, 'lat': spots.latitudeMaster, 'lng': spots.longitudeMaster });

                    // Return Array-Value
                    return true;
                    
                }
                
            });

        },
    },
    mounted() {

        // Disable the "Add Spot" Button
        this.$store.commit('changeSpotButtonState', true);
    }
}
</script>
<template>
    <div>
        <div>
            <b-button class="btn-light" variant="light" v-on:click="positiveTagsShow"><i class="material-icons">star_outline</i></b-button>
            <b-button class="btn-light" variant="light" v-on:click="allTagsShow">My Spots</b-button>
        </div>
        <div class="mt-4" v-show="!warningContainer">
            <h3>Your Spots</h3>
        </div>
        <div>
            <ul class="center-align">
                    <li class="tag" :id="'tag-information-' + mySpots.id" v-for="mySpots in this.$store.state.arrays.mySpots" :key="mySpots.id">
                        <h3>0 km</h3>
                        {{ mySpots.title }}
                        <b-popover :target="'tag-information-' + mySpots.id" triggers="hover" placement="top">
                            <template v-slot:title>{{ mySpots.name }}</template>
                            {{ mySpots.information }}
                        </b-popover>
                    </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Favorites",
    data() {
        return {
            warningContainer: Boolean,
        }
    },
    mounted() {

        // Disable the "Add Spot" Button
        this.$store.commit('changeSpotButtonState', true);

    }
}
</script>
<template>
  <div>
    <gmap-map
      :center="center"
      :zoom="zoom"
      :options="{minZoom: 4, maxZoom: 35}">
      style="width: 100%; height: 50%; position: relative; left:0; top:0">
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
// Import framework(s)
import Vue from 'vue'
import { store } from '../../store.js'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDzxi2eUeaRn5zKajeiD8pWm51_meUvkOc',
    libraries: 'places',
  }
});

export default {
  name: 'ReportMap',
    data () {
      return {
        center: {},
        zoom: Number,
        markers: [],
      }
    },
    methods: {
      loadMapData() {
        var _this = this;
        this.markers = [];
        
        store.state.arrays.mapMarker.forEach((value) => {
          _this.markers.push({position: { lat: value[1], lng: value[2] }});
        })
        
      },
      loadMapCenter() {
        this.center = {};
        this.center = { lat: store.state.user.curCenterLatitude, lng: store.state.user.curCenterLongitude };
      },
      loadMapZoom() {
        this.zoom = store.state.user.curMapZoom;
      },
    },
    created() {

      this.$store.watch(
        (state)=>{
          return state.arrays.mapMarker
        },()=>{
      
          // Reload Data of Map
          this.loadMapData();

          // Return new marker
          return this.markers;

        })

      if(this.$store.state.user.curCenterLatitude != 0 && this.$store.state.user.curCenterLongitude != 0) {

        this.center = { lat: store.state.user.curCenterLatitude, lng: store.state.user.curCenterLongitude };
          
        // Return new marker
        return this.center;

      } else {

       this.$store.watch( 
        (state)=>{
          return {curCenterLatitude: state.user.curCenterLatitude, curCenterLogintude: state.user.curCenterLongitude }
        },()=>{

          // Reload Data of Map
          this.loadMapCenter();

          // Return new marker
          return this.center;

        })
      
      }

      if(this.$store.state.user.curMapZoom != 0) {

        this.zoom = store.state.user.curMapZoom;
          
        // Return new marker
        return this.zoom;

      } else {

       this.$store.watch(
        (state)=>{
          return state.user.curMapZoom
        },()=>{

          // Reload Data of Map
          this.loadMapZoom();

          // Return new marker
          return this.zoom;

        })
      
      }      


    },
    mounted: function() {

      // Load Data of Map
      this.loadMapData();

    }
}
</script>

<style scoped>
  .vue-map-container {
    height: 66.6vh !important;
  }

  .map-proxy {
    height: -o-calc(100vh - 26px); /* opera */
    height: -webkit-calc(100vh - 26px); /* google, safari */
    height: -moz-calc(100vh - 26px); /* firefox */
    margin: 0 auto;
  }

  .google-map {
    height: -o-calc(100vh - 26px); /* opera */
    height: -webkit-calc(100vh - 26px); /* google, safari */
    height: -moz-calc(100vh - 26px); /* firefox */
    margin: 0 auto;
  }
</style>

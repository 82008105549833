<template>
    <div>
        <div class="mt-4" v-show="!contentLoaded">
            <h3>Select a tag</h3>
        </div>
        <div>
            <ul class="center-align">
                    <li class="tag" :id="'tag-information-' + tags.id" v-for="tags in filteredTags" :key="tags.id">
                        {{ tags.name }}
                        <b-popover :target="'tag-information-' + tags.id" triggers="hover" placement="top">
                            <template v-slot:title>{{ tags.name }}</template>
                            {{ tags.information }}
                        </b-popover>
                    </li>
                <li class="tag" v-show="!contentLoaded">suggest new tag</li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        name: "AddContainer",
        data() {
            return {
                contentLoaded: Boolean,
            }
        },
        computed: {
            filteredTags() {

                // Handle unset 
                if (!this.$store.state.arrays.allTags) return [];

                // Filter Array
                return this.$store.state.arrays.allTags.filter(tags =>  {

                    // Use homeCondition
                    if(tags.rank.includes(this.$store.state.conditions.homeCondition)) {

                        // Set Content to loaded
                        this.contentLoaded = false;

                        // Return Array-Value
                        return true;
                        
                    }
                    
                });

            },
        },
        created() {

        // Clear all Markers from Map
        this.$store.commit("clearMapMarker");

        if(this.$store.state.user.curUserLatitude != 0 && this.$store.state.user.curUserLongitude != 0) {

            // Show Markers on Map
            this.$store.commit("setMapZoom", 15);
            this.$store.commit("setMapMarker", { 'id': 1, 'lat': this.$store.state.user.curUserLatitude, 'lng': this.$store.state.user.curUserLongitude });    
            this.$store.commit("setMapCenter", { 'lat': this.$store.state.user.curUserLatitude, 'lng': this.$store.state.user.curUserLongitude });

        } else {

        this.$store.watch(

            (state)=>{
            return {curUserLatitude: state.user.curUserLatitude, curUserLogintude: state.user.curUserLongitude }
            },()=>{

                // Show Markers on Map
                this.$store.commit("setMapZoom", 15);
                this.$store.commit("setMapMarker", { 'id': 1, 'lat': this.$store.state.user.curUserLatitude, 'lng': this.$store.state.user.curUserLongitude });
                this.$store.commit("setMapCenter", { 'lat': this.$store.state.user.curUserLatitude, 'lng': this.$store.state.user.curUserLongitude });

            })

        }

        },
        mounted() {

            // Show "Add Spot" Button
            this.$store.commit('changeSpotButtonState', false);
            
        },
    }

</script>

<style>

    li.tag {
        list-style: none;
        background-color: white;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
        -moz-box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
        box-shadow: 0px 0px 5px 0px rgba(184,178,184,1);
        width: 200px;
        padding: 1rem;
        margin: 1rem;
        float: left;
        border-radius: .5rem;
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    li.tag:hover {
        -webkit-box-shadow: 0px 0px 15px 0px rgba(184,178,184,1);
        -moz-box-shadow: 0px 0px 15px 0px rgba(184,178,184,1);
        box-shadow: 0px 0px 15px 0px rgba(184,178,184,1);
        cursor: pointer;
    }

    h2, h3 {
        font-weight: normal;
        color:grey;
    }

</style>
